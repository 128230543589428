import React from "react"
import { StaticQuery, graphql } from "gatsby"
import TermsPageComponent from "../page-components/TermsPageComponent"

const privacyPolicyQuery = graphql`
  query {
    page: markdownRemark(fields: { slug: { eq: "/pages/privacy-policy/" } }) {
      frontmatter {
        text
        seoTitle
        seoDescription
        seoImage {
          childImageSharp {
            fixed(width: 480, quality: 92) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
const PrivacyPolicyPage = props => (
  <StaticQuery
    query={privacyPolicyQuery}
    render={data => {
      const {
        page: {
          frontmatter: { seoTitle, seoDescription, seoImage, text },
        },
        features,
      } = data

      return (
        <TermsPageComponent
          features={features}
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoImage={
            (seoImage &&
              seoImage.childImageSharp &&
              seoImage.childImageSharp.fixed &&
              seoImage.childImageSharp.fixed.src) ||
            ""
          }
          text={text}
          url="https://cloudware.pt/politica-privacidade"
          {...props}
        />
      )
    }}
  />
)

export default PrivacyPolicyPage
